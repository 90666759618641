/* eslint-disable react/jsx-no-target-blank */
import {Fragment} from 'react'
import {DEMAND_PATH} from '../../../../helpers'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => (
  <Fragment>
    {/* <div className='menu-item'>
      <div className='menu-content pt-8 pb-2'>
        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>CRM</span>
      </div>
    </div> */}
    <SidebarMenuItem
      to='/customers'
      title='Quản lý khách hàng'
      icon='/media/biha/icons/svg/fluent_text-bullet-list-square-toolbox-20-filled.svg'
    />
    <SidebarMenuItem
      to={`/${DEMAND_PATH.MAIN}`}
      title='Tất cả nhu cầu khách hàng'
      icon='/media/biha/icons/svg/hand-care-1.svg'
    />
    <SidebarMenuItem
      to='/contract-configuration'
      title='Cấu hình loại hợp đồng'
      icon='/media/biha/icons/svg/wrench.svg'
    />
    <SidebarMenuItem
      to='/contracts'
      title='Tất cả hợp đồng'
      icon='/media/biha/icons/svg/file-folder.svg'
    />
    {/* <SidebarMenuItem
      to='/demands/approved-list'
      title='Nhu cầu đã đăng lên sàn'
      icon='/media/biha/icons/svg/hand-care-2.svg'
    /> */}
  </Fragment>
)

export {SidebarMenuMain}
